import React, { useContext, useEffect, useState } from 'react'
import ItemNotification from './ItemNotification'
import { navigate } from 'gatsby'
import NotificationContext from '../../context/NotificationContext'
import { motion, useAnimation } from 'framer-motion'
import { globalHistory } from '@reach/router'

const variants = {
  initial: { opacity: 0, x: 500 },
  animate: { opacity: 1, x: 0, transition: { duration: 0.3 } },
  exit: { opacity: 0, x: 500, transition: { duration: 0.3 } },
}

export default function ItemNotificationManager() {
  const controls = useAnimation()
  const { addedItem, closeCurrentItem } = useContext(NotificationContext)
  const [showingItem, setShowingItem] = useState(null)

  useEffect(() => {
    const setItem = async () => {
      if (addedItem == null) {
        return
      }

      const { isClosed } = addedItem

      if (showingItem != null && !showingItem.isClosed) {
        await controls.start('exit')
      }

      setShowingItem(addedItem)

      // We check if its new by checking if it is closed
      // if its false, then we assume its new.
      if (!isClosed) {
        await controls.start('animate')
      }
    }
    setItem()
  }, [addedItem])

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') closeCurrentItem()
    })
  }, [closeCurrentItem])

  const handleSeeShoppingCart = async () => {
    await controls.start('exit')
    closeCurrentItem()
    return navigate('/cart')
  }

  return (
    <div className="relative" style={{ zIndex: '50' }}>
      <motion.div
        className="item-notification"
        initial="initial"
        animate={controls}
        variants={variants}
      >
        {showingItem != null && (
          <ItemNotification
            title={showingItem.title}
            quantity={showingItem.quantity}
            price={showingItem.price}
            image={showingItem.image}
            imageAlt={showingItem.imageAlt}
            onClose={closeCurrentItem}
          >
            <button
              onClick={handleSeeShoppingCart}
              className="mt-4 btn-small btn-yellow-outline self-center md:mt-6"
            >
              SEE SHOPPING CART
            </button>
          </ItemNotification>
        )}
      </motion.div>
    </div>
  )
}
