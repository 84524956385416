import React, { useEffect } from 'react'
import clsx from 'clsx'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

const variants = {
  initial: { opacity: 0, y: 50 },
  start: delayIdx => ({
    y: 0,
    opacity: 1,
    transition: { delay: (delayIdx ?? 0) * 0.35, duration: 0.6 },
  }),
}

const EndingSection = function EndingSection({
  className,
  isReversed,
  imgBuilder,
  titleBuilder,
  descriptionBuilder,
  buttonBuilder,
}) {
  const controls = useAnimation()
  const { ref, inView } = useInView({ threshold: 0.5 })

  useEffect(() => {
    if (inView) {
      controls.start('start')
    }
  }, [controls, inView])

  return (
    <section
      ref={ref}
      className={clsx(
        'container-small mx-auto wine-club-bg flex flex-col',
        isReversed ? 'md:flex-row-reverse' : 'md:flex-row',
        className
      )}
    >
      {imgBuilder != null && imgBuilder('object-cover md:w-1/2')}
      <motion.div
        initial="initial"
        animate={controls}
        variants={variants}
        className="p-16 flex flex-col items-start justify-center md:w-1/2"
      >
        {titleBuilder != null && titleBuilder()}
        {descriptionBuilder != null && descriptionBuilder()}
        {buttonBuilder != null && buttonBuilder()}
      </motion.div>
    </section>
  )
}

export default EndingSection
