import React, { useCallback, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Image from 'gatsby-image'
import clsx from 'clsx'
import SpinnableButton from '../shared/SpinnableButton'
import _ from 'lodash'
import StoreContext from '../../context/StoreContext'
import { getRawBottleFeeVariantIdFromProduct } from '../../utils/product-utils'
import { motion } from 'framer-motion'
import NotificationContext from '../../context/NotificationContext'
import getPrice from '../../utils/getPrice'

const Spinner = () => {
  return (
    <svg
      className="animate-spin ml-2 h-5 w-5 text-yellow"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      />
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </svg>
  )
}

const calculateAfterTaxPrice = strPrice => {
  return (
    Math.round((parseFloat(strPrice) * 1.15 + Number.EPSILON) * 100) / 100
  ).toFixed(2)
}

CollectionProductCard.propTypes = {
  title: PropTypes.string.isRequired,
  product: PropTypes.shape({}).isRequired,
  subtitle: PropTypes.string,
  fluidProductImg: PropTypes.shape({}),
  productImgAlt: PropTypes.string,
  description: PropTypes.string,
  className: PropTypes.string,
  addToCartText: PropTypes.string,
  alcohol: PropTypes.string,
  pairings: PropTypes.string,
  awards: PropTypes.string,
  isAfterTaxPrice: PropTypes.bool,
}

export default function CollectionProductCard({
  title,
  subtitle,
  product,
  smallerImage,
  smallImage,
  bigImage,
  productImgAlt,
  description,
  className,
  alcohol,
  pairings,
  awards,
  addToCartText = 'ADD TO CART',
  isAfterTaxPrice = true,
}) {
  const variants = product?.variants ?? []

  const {
    addVariantToCart,
    store: { client },
  } = useContext(StoreContext)
  const { addItem } = useContext(NotificationContext)

  const [variant, setVariant] = useState({
    ..._.get(product, 'variants.0', {}),
  })
  const [isAdding, setIsAdding] = useState(false)
  const [amount, setAmount] = useState(1)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [available, setAvailable] = useState(
    _.get(variant, 'availableForSale', false)
  )
  const [isLoadingAvailability, setIsLoadingAvailability] = useState(true)

  const variantId = _.get(variant, 'shopifyId')
  let toShowPrice = _.get(variant, 'price')

  if (toShowPrice != null && toShowPrice !== '' && isAfterTaxPrice) {
    toShowPrice = calculateAfterTaxPrice(toShowPrice)
  }

  const checkAvailability = useCallback(
    async productId => {
      client.product.fetch(productId).then(fetchedProduct => {
        // If fetchedProduct is null, that is a sign that it is not available
        // entirely.
        // This only happens in the case that the statically generated
        // site has stale data, and a product was removed.
        if (fetchedProduct?.variants == null) {
          setAvailable(false)
          return
        }

        // this checks the currently selected variant for availability
        const result = fetchedProduct.variants.filter(
          variant => variant.id === variantId
        )

        if (result.length > 0) {
          setAvailable(result[0].available)
        } else {
          // Nothing was found, so it must not be available.
          setAvailable(false)
        }
      })
    },
    [client.product, variantId]
  )

  useEffect(() => {
    const check = async () => {
      setIsLoadingAvailability(true)
      try {
        await checkAvailability(product.shopifyId)
      } catch (e) {
        console.error(e)
      }
      setIsLoadingAvailability(false)
    }

    check()
  }, [variantId, checkAvailability, product.shopifyId])

  const handleAddToCart = async () => {
    setIsAdding(true)
    try {
      await addVariantToCart(
        variantId,
        amount,
        getRawBottleFeeVariantIdFromProduct(product)
      )
    } catch (e) {
      console.error(e)
    }

    const finalPrice = getPrice(amount * parseFloat(toShowPrice))

    addItem({
      title,
      quantity: amount,
      price: finalPrice,
      image: smallerImage,
      imageAlt: productImgAlt,
    })

    setIsAdding(false)
  }

  const handleChangeAmount = ({ target }) => {
    setAmount(target.value)
  }

  const handleVariantChange = ({ target }) => {
    const { value: selectedVariantId } = target

    const selectedVariant = _.find(variants, {
      id: selectedVariantId,
    })

    setVariant({ ...selectedVariant })
  }

  return (
    <div className={clsx('product', className)}>
      <div
        onClick={() => setIsModalOpen(true)}
        className="product-header cursor-pointer"
      >
        {smallImage != null && (
          <Image
            className="product-image flex justify-center w-full"
            fluid={smallImage}
            alt={productImgAlt}
          />
        )}
        <h1 className="mt-4 text-center">{title}</h1>
        {subtitle != null && subtitle !== '' && (
          <p className="text-sm text-gray-body my-2">{subtitle}</p>
        )}
        <p>${toShowPrice}</p>
      </div>
      <div className="mt-4 px-8 flex flex-col items-center space-y-4 w-full">
        {variants.length > 1 && (
          <select
            className="w-full py-2 outline-none focus:outline-none bg-input text-white"
            value={variant.id}
            onChange={handleVariantChange}
          >
            {variants.map(({ id, title }) => {
              return (
                <option value={id} key={id}>
                  {title}
                </option>
              )
            })}
          </select>
        )}
        {available ? (
          <div className="flex">
            <div className="w-12 bg-input flex items-center justify-center">
              <input
                className="appearance-none outline-none focus:outline-none w-8 h-full bg-input text-white"
                type="number"
                name="quantity"
                min="1"
                max="99"
                value={amount}
                onChange={handleChangeAmount}
              />
            </div>
            <SpinnableButton
              isLoading={isAdding || isLoadingAvailability}
              className="btn-yellow-outline ml-2"
              onClick={handleAddToCart}
            >
              {addToCartText}
            </SpinnableButton>
          </div>
        ) : (
          <h2 className="text-yellow addington out-of-stock">
            {isLoadingAvailability ? <Spinner /> : 'Out of stock'}
          </h2>
        )}
      </div>
      {isModalOpen && (
        <div className="modal z-10 fixed w-full h-full top-0 left-0 items-center justify-center">
          <div className="modal-overlay absolute w-full h-full bg-gray-500 opacity-75" />
          <div className="relative py-4 px-4 h-full w-full z-50">
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              className="modal-container bg-white h-full border-4 border-gray-700 shadow-lg overflow-y-auto overscroll-contain md:max-w-6xl md:mx-auto"
            >
              <div className="modal-content py-6 text-left px-6">
                <div className="flex flex-col md:hidden">
                  <button
                    onClick={() => setIsModalOpen(false)}
                    className="btn-small btn-yellow-outline self-end"
                  >
                    &#215;
                  </button>
                  <div className="mt-4 w-full flex justify-center">
                    <Image
                      className="w-full"
                      fluid={smallImage}
                      alt={productImgAlt}
                    />
                  </div>
                  <h1 className="mt-4 text-center text-gray-title">{title}</h1>
                  <hr className="yellow-line" />
                  <div className="flex flex-col text-gray-body mt-4 space-y-4">
                    <div className="product-size">
                      <span>{subtitle}</span>
                    </div>
                    {alcohol && (
                      <div className="product-alcohol flex flex-col items-start">
                        <span>Alcohol Content</span>
                        <span>{alcohol}%</span>
                      </div>
                    )}
                    {description && (
                      <p className="leading-relaxed">{description}</p>
                    )}
                    {pairings && (
                      <div>
                        <h1 className="font-light text-gray-title">Pairings</h1>
                        <div className="product-pairings">{pairings}</div>
                      </div>
                    )}
                    {awards && (
                      <div>
                        <h1 className="font-light text-gray-title">
                          Awards & Recognition
                        </h1>
                        <div
                          className="flex flex-col space-y-2 product-awards"
                          dangerouslySetInnerHTML={{ __html: awards }}
                        />
                      </div>
                    )}
                    <div className="mt-4 px-8 flex flex-col items-center space-y-4">
                      {variants.length > 1 && (
                        <select
                          className="w-full py-2 outline-none focus:outline-none bg-input text-white"
                          value={variant.id}
                          onChange={handleVariantChange}
                        >
                          {variants.map(({ id, title }) => {
                            return (
                              <option value={id} key={id}>
                                {title}
                              </option>
                            )
                          })}
                        </select>
                      )}
                      {available ? (
                        <div className="flex">
                          <div className="w-12 bg-input flex items-center justify-center">
                            <input
                              className="appearance-none outline-none focus:outline-none w-8 h-full bg-input text-white"
                              type="number"
                              name="quantity"
                              min="1"
                              max="99"
                              value={amount}
                              onChange={handleChangeAmount}
                            />
                          </div>
                          <SpinnableButton
                            isLoading={isAdding || isLoadingAvailability}
                            className="btn-yellow-outline ml-2"
                            onClick={handleAddToCart}
                          >
                            {addToCartText}
                          </SpinnableButton>
                        </div>
                      ) : (
                        <h2 className="text-yellow addington out-of-stock">
                          {isLoadingAvailability ? <Spinner /> : 'Out of stock'}
                        </h2>
                      )}
                    </div>
                  </div>
                </div>
                <div className="hidden flex-col md:flex">
                  <button
                    onClick={() => setIsModalOpen(false)}
                    className="btn-small btn-yellow-outline self-end"
                  >
                    &#215;
                  </button>
                  <div className="flex w-full self-center">
                    <div className="w-1/2 flex flex-col items-center justify-center">
                      {bigImage != null && (
                        <Image
                          className="w-full"
                          fluid={bigImage}
                          alt={productImgAlt}
                        />
                      )}
                    </div>
                    <div className="w-1/2 flex flex-col items-start">
                      <div className="mx-16 flex flex-col items-start">
                        <h1 className="mt-4 text-center text-gray-title">
                          {title}
                        </h1>
                        <hr className="yellow-line w-full" />
                        <div className="flex flex-col text-gray-body mt-4 space-y-4">
                          <div className="product-size">
                            <span>{subtitle}</span>
                          </div>
                          {alcohol && (
                            <div className="product-alcohol flex flex-col items-start">
                              <span>Alcohol Content</span>
                              <span>{alcohol}%</span>
                            </div>
                          )}
                          {description && (
                            <p className="leading-relaxed">{description}</p>
                          )}
                          {pairings && (
                            <>
                              <h1 className="font-light text-gray-title">
                                Pairings
                              </h1>
                              <div className="product-pairings">
                                <span>{pairings}</span>
                              </div>
                            </>
                          )}
                          {awards && (
                            <>
                              <h1 className="font-light text-gray-title">
                                Awards & Recognition
                              </h1>
                              <div
                                className="flex flex-col space-y-2 product-awards"
                                dangerouslySetInnerHTML={{ __html: awards }}
                              />
                            </>
                          )}
                          <div className="mt-4 flex flex-col space-y-4">
                            {variants.length > 1 && (
                              <select
                                className="w-full py-2 outline-none focus:outline-none bg-input text-white"
                                value={variant.id}
                                onChange={handleVariantChange}
                              >
                                {variants.map(({ id, title }) => {
                                  return (
                                    <option value={id} key={id}>
                                      {title}
                                    </option>
                                  )
                                })}
                              </select>
                            )}
                            {available ? (
                              <div className="flex">
                                <div className="w-12 bg-input flex items-center justify-center">
                                  <input
                                    className="appearance-none outline-none focus:outline-none w-8 h-full bg-input text-white"
                                    type="number"
                                    name="quantity"
                                    min="1"
                                    max="99"
                                    value={amount}
                                    onChange={handleChangeAmount}
                                  />
                                </div>
                                <SpinnableButton
                                  isLoading={isAdding || isLoadingAvailability}
                                  className="btn-yellow-outline ml-2"
                                  onClick={handleAddToCart}
                                >
                                  {addToCartText}
                                </SpinnableButton>
                              </div>
                            ) : (
                              <h2 className="text-yellow addington out-of-stock">
                                {isLoadingAvailability ? (
                                  <Spinner />
                                ) : (
                                  'Out of stock'
                                )}
                              </h2>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      )}
    </div>
  )
}
