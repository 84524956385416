import React, { useState } from 'react'
import PropTypes from 'prop-types'
import CollectionFilters from './CollectionFilters'
import _ from 'lodash'
import CollectionProductGrid from './CollectionProductGrid'
import Sticky from 'react-stickynode'
import { scroller } from 'react-scroll'

Collections.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      collectionId: PropTypes.string.isRequired,
    })
  ).isRequired,
  collectionToProducts: PropTypes.shape({}).isRequired,
}

export default function Collections({ filters, collectionToProducts }) {
  const [currCollectionId, setCurrCollectionId] = useState(
    _.get(filters, '0.collectionId', 'Default')
  )

  const currProducts = collectionToProducts[currCollectionId] ?? []
  const scrollToId = 'empty-div'

  const handleChangeFilter = newCollectionId => {
    setCurrCollectionId(newCollectionId)
    scroller.scrollTo(scrollToId, {
      duration: 300,
      delay: 0,
      offset: -100,
      ignoreCancelEvents: true,
      isDynamic: true,
      smooth: true,
    })
  }

  return (
    <>
      <div id={scrollToId} />
      <div id="collections">
        <Sticky top="#inner-navbar" bottomBoundary="#collections" innerZ={1}>
          <CollectionFilters
            filters={filters}
            currentFilterId={currCollectionId}
            onChangeFilter={handleChangeFilter}
          />
        </Sticky>
        <CollectionProductGrid products={currProducts} />
      </div>
    </>
  )
}
