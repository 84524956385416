import React from 'react'
import _ from 'lodash'
import { graphql } from 'gatsby'
import PageLayout from '../components/PageLayout'
import FeaturedProduct from '../components/Shop/FeaturedProduct'
import EndingSectionSlice from '../components/slices/EndingSectionSlice'
import Collections from '../components/Shop/Collections'
import SEO from '../components/seo'
import ItemNotificationManager from '../components/ItemNotification/ItemNotificationManager'

export const query = graphql`
  query ShopPageQuery(
    $uid: String
    $lang: String
    $collectionIds: [String]
    $featuredProductShopifyId: String
  ) {
    prismicShopPage(uid: { eq: $uid }, lang: { eq: $lang }) {
      id
      uid
      data {
        page_title {
          text
        }
        page_description {
          text
        }
        add_to_cart_button_text {
          text
        }
        collections_to_show {
          collection_id {
            text
          }
          name {
            text
          }
        }
        body {
          ... on PrismicShopPageBodyEndingSection {
            id
            slice_type
            slice_label
            primary {
              title {
                text
              }
              description {
                text
              }
              button_text {
                text
              }
              button_link {
                url
                link_type
                target
              }
              image {
                alt
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              is_reversed
            }
          }
        }
        body1 {
          ... on PrismicShopPageBody1Collection {
            id
            slice_type
            primary {
              name {
                text
              }
              collection_id {
                text
              }
            }
          }
        }
        header {
          text
        }
        page_title {
          text
        }
        product_subtitle {
          text
        }
        product_title {
          text
        }
        description {
          text
        }
        product_showcase_image {
          alt
          localFile {
            childImageSharp {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        product_background_details_image {
          alt
          localFile {
            childImageSharp {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
    allShopifyCollection(filter: { handle: { in: $collectionIds } }) {
      edges {
        node {
          handle
          products {
            id
            title
            handle
            description
            createdAt
            shopifyId
            metafields {
              namespace
              key
              value
            }
            options {
              id
              name
              values
            }
            images {
              id
              originalSrc
              smallerImage: localFile {
                childImageSharp {
                  fluid(maxHeight: 100) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    ...GatsbyImageSharpFluidLimitPresentationSize
                  }
                }
              }
              smallImage: localFile {
                childImageSharp {
                  fluid(maxHeight: 375) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    ...GatsbyImageSharpFluidLimitPresentationSize
                  }
                }
              }
              bigImage: localFile {
                childImageSharp {
                  fluid(maxHeight: 910) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    ...GatsbyImageSharpFluidLimitPresentationSize
                  }
                }
              }
            }
            variants {
              id
              title
              availableForSale
              shopifyId
              price
              selectedOptions {
                name
                value
              }
            }
          }
        }
      }
    }
    shopifyProduct(shopifyId: { eq: $featuredProductShopifyId }) {
      id
      title
      handle
      shopifyId
      metafields {
        namespace
        key
        value
      }
      options {
        id
        name
        values
      }
      variants {
        id
        title
        availableForSale
        shopifyId
        price
        selectedOptions {
          name
          value
        }
      }
      images {
        id
        originalSrc
        smallerImage: localFile {
          childImageSharp {
            fluid(maxHeight: 100) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
    }
    prismicNavigationBar(lang: { eq: $lang }) {
      ...NavigationBarFragment
    }
    prismicLeftNavigationMenu(lang: { eq: $lang }) {
      ...LeftNavigationMenuFragment
    }
    prismicRightNavigationMenu(lang: { eq: $lang }) {
      ...RightNavigationMenuFragment
    }
    prismicStayConnectedSection(lang: { eq: $lang }) {
      ...StayConnectedSectionFragment
    }
    prismicBottomNavigationBar(lang: { eq: $lang }) {
      ...BottomNavigationBarFragment
    }
    prismicCookieBanner(lang: { eq: $lang }) {
      ...CookieBannerFragment
    }
  }
`

const AfterShopSlices = function PageSlices({ slices }) {
  if (slices == null || slices.length === 0) {
    return []
  }

  const renderedSlices = slices.map(slice => {
    const { slice_type: sliceType, id } = slice
    switch (sliceType) {
      case 'ending_section':
        // Ending Section
        return <EndingSectionSlice key={id} slice={slice} />
      default:
        return null
    }
  })

  return _.compact(renderedSlices)
}

export default function ShopPage({ data }) {
  const { prismicShopPage: shopPage } = data

  const pageTitle = _.get(shopPage, ['data', 'page_title', 'text'])
  const pageDescription = _.get(shopPage, 'data.page_description.text')

  const addToCartButtonText = _.get(
    shopPage,
    'data.add_to_cart_button_text.text',
    'ADD TO CARD'
  )
  const afterShopSlices = _.get(shopPage, ['data', 'body'], [])
  const collections = _.get(shopPage, ['data', 'collections_to_show'], [])
  const featuredProductInfo = {
    header: _.get(shopPage, 'data.header.text', ''),
    productTitle: _.get(shopPage, 'data.product_title.text', ''),
    productSubtitle: _.get(shopPage, 'data.product_subtitle.text', ''),
    description: _.get(shopPage, 'data.description.text', ''),
    productImage: _.get(shopPage, 'data.product_showcase_image'),
    productBackgroundDetailsImage: _.get(
      shopPage,
      'data.product_background_details_image'
    ),
  }

  const mappedCollectionFilters = collections.map(collection => {
    const name = _.get(collection, 'name.text')
    const collectionId = _.get(collection, 'collection_id.text')

    if (
      name == null ||
      collectionId == null ||
      name === '' ||
      collectionId === ''
    ) {
      return null
    }

    return {
      name,
      collectionId,
    }
  })

  const collectionProductsData = _.get(data, 'allShopifyCollection.edges', [])
  const collectionToProducts = {}
  collectionProductsData.forEach(edge => {
    const collectionId = _.get(edge, 'node.handle')
    const products = _.get(edge, 'node.products', [])

    if (collectionId == null || collectionId === '') {
      return
    }
    collectionToProducts[collectionId] = products
  })
  const featuredProduct = _.get(data, 'shopifyProduct', null)

  return (
    <PageLayout pageData={data}>
      <SEO title={pageTitle} description={pageDescription} />
      <ItemNotificationManager />
      {featuredProduct != null && (
        <FeaturedProduct
          product={featuredProduct}
          header={featuredProductInfo.header}
          title={featuredProductInfo.productTitle}
          subtitle={featuredProductInfo.productSubtitle}
          description={featuredProductInfo.description}
          productImage={featuredProductInfo.productImage}
          backgroundImage={featuredProductInfo.productBackgroundDetailsImage}
          buttonText={addToCartButtonText}
        />
      )}
      <Collections
        filters={_.compact(mappedCollectionFilters)}
        collectionToProducts={collectionToProducts}
      />
      <AfterShopSlices slices={afterShopSlices} />
    </PageLayout>
  )
}
