import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import Image from 'gatsby-image'

ItemNotification.propTypes = {
  title: PropTypes.string.isRequired,
  quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  image: PropTypes.shape({}),
  imageAlt: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  onClose: PropTypes.func,
  className: PropTypes.string,
}

ItemNotification.defaultProps = {
  quantity: null,
  price: null,
  image: null,
  className: null,
  children: null,
  onClose: undefined,
  imageAlt: undefined,
}
export default function ItemNotification({
  title,
  quantity,
  price,
  image,
  imageAlt,
  children,
  className,
  onClose,
  ...otherProps
}) {
  return (
    <div
      className={clsx(
        'bg-white border-gray-700 border-2 flex flex-col shadow-lg',
        className
      )}
      {...otherProps}
    >
      <div className="flex justify-between items-center pl-2 md:pl-4">
        <span className="text-gray-body text-sm">
          Item was successfully added to your cart
        </span>
        <button
          onClick={onClose}
          className="btn-small btn-yellow-outline self-end"
        >
          &#215;
        </button>
      </div>
      <div className="flex flex-col items-start p-2 md:p-4">
        <div className="flex items-center">
          {image && (
            <Image
              fluid={image}
              alt={imageAlt}
              className="cart-img flex justify-center w-full"
            />
          )}
          <div className={clsx('flex flex-col', image && 'ml-2 md:ml-4')}>
            <h1 className="text-base text-gray-title md:text-xl">{title}</h1>
            <div className="flex mt-2 text-gray-body">
              {quantity && (
                <p className="text-sm md:text-base">Qty: {quantity}</p>
              )}
              {price && (
                <p className={clsx('text-sm md:text-base', quantity && 'ml-4')}>
                  ${price}
                </p>
              )}
            </div>
          </div>
        </div>
        {children}
      </div>
    </div>
  )
}
