import _ from 'lodash'
import getFluidImg from '../../utils/getFluidImg'
import Image from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image-es5'
import React, { useContext, useState } from 'react'
import StoreContext from '../../context/StoreContext'
import {
  getMetafieldFromProduct,
  getRawBottleFeeVariantIdFromProduct,
  shopifyFormatMoney,
} from '../../utils/product-utils'
import SpinnableButton from '../shared/SpinnableButton'
import { motion } from 'framer-motion'
import getPrice from '../../utils/getPrice'
import NotificationContext from '../../context/NotificationContext'

const variants = {
  initial: { opacity: 0, y: -50 },
  start: { opacity: 1, y: 0, transition: { delay: 0.1, duration: 0.6 } },
}

export default function FeaturedProduct({
  product,
  header,
  title,
  subtitle,
  description,
  buttonText,
  productImage,
  backgroundImage,
  className,
}) {
  const { addVariantToCart } = useContext(StoreContext)
  const { addItem } = useContext(NotificationContext)
  const [isAdding, setIsAdding] = useState(false)
  const variant = _.get(product, 'variants.0')
  const variantId = _.get(variant, 'shopifyId')

  const productAltText = _.get(productImage, 'alt', 'product image')
  const productFluidImg = getFluidImg(productImage)
  const backgroundFluidImg = getFluidImg(backgroundImage)

  const desktopBackgroundStack = [
    'linear-gradient(rgba(0, 0, 0, 0.7) 100%, rgba(0, 0, 0, 0.7) 100%)',
    backgroundFluidImg,
  ]

  const mobileBackgroundStack = [
    'linear-gradient(rgba(0, 0, 0, 0.3) 100%, rgba(0, 0, 0, 0.3) 100%)',
    productFluidImg,
  ]

  const handleAddToCart = async () => {
    setIsAdding(true)
    try {
      await addVariantToCart(
        variantId,
        1,
        getRawBottleFeeVariantIdFromProduct(product)
      )

      const afterTaxPricing = getMetafieldFromProduct(
        product,
        'pricing',
        'after_tax_price'
      )

      const normalPrice = variant.price

      const rawPrice = parseFloat(
        afterTaxPricing
          ? shopifyFormatMoney(afterTaxPricing, '{{amount}}')
          : normalPrice
      )

      let finalPrice = null

      if (rawPrice != null && !isNaN(rawPrice)) {
        finalPrice = getPrice(rawPrice)
      }

      const image = _.get(
        product,
        'images.0.smallerImage.childImageSharp.fluid'
      )

      addItem({
        title,
        quantity: 1,
        price: finalPrice,
        image: image,
        imageAlt: product.handle,
      })
    } catch (e) {
      console.error(e)
    }

    setIsAdding(false)
  }

  return (
    <section className={className}>
      <div className="h-screen hidden md:flex">
        {productFluidImg != null && (
          <Image
            fluid={productFluidImg}
            alt={productAltText}
            className="object-cover md:w-1/2"
          />
        )}
        <BackgroundImage
          Tag="div"
          fluid={desktopBackgroundStack}
          className="w-1/2 h-full flex items-center"
        >
          <motion.div
            initial="initial"
            animate="start"
            variants={variants}
            className="flex flex-col items-start text-white ml-16"
          >
            <h1 className="notera">{header}</h1>
            <hr className="w-full" />
            <h1>{title}</h1>
            <h1>{subtitle}</h1>
            <p>{description}</p>
            <SpinnableButton
              isLoading={isAdding}
              onClick={handleAddToCart}
              className="btn-white-outline mt-6"
            >
              {buttonText}
            </SpinnableButton>
          </motion.div>
        </BackgroundImage>
      </div>
      <BackgroundImage
        Tag="div"
        fluid={mobileBackgroundStack}
        className="hero flex items-center justify-center select-none md:hidden"
      >
        <motion.div
          initial="initial"
          animate="start"
          variants={variants}
          className="flex flex-col items-center text-white px-6 py-6 mx-6 mt-16 bg-black bg-opacity-75 text-center"
        >
          <h1 className="notera">{header}</h1>
          <hr className="w-full" />
          <h1>{title}</h1>
          <h1>{subtitle}</h1>
          <p>{description}</p>
          <SpinnableButton
            isLoading={isAdding}
            onClick={handleAddToCart}
            className="btn-white-outline mt-6"
          >
            {buttonText}
          </SpinnableButton>
        </motion.div>
      </BackgroundImage>
    </section>
  )
}
