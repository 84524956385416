import React, { useState } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

CollectionFilters.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      collectionId: PropTypes.string.isRequired,
    })
  ).isRequired,
  currentFilterId: PropTypes.string.isRequired,
  onChangeFilter: PropTypes.func,
  className: PropTypes.string,
}

export default function CollectionFilters({
  filters,
  currentFilterId,
  onChangeFilter,
  className,
}) {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const currentFilter = filters.filter(
    collection => collection.collectionId === currentFilterId
  )

  const currentFilterName =
    currentFilter.length !== 1 ? 'Default' : currentFilter[0].name

  return (
    <section
      className={clsx(
        'collection-filters bg-white shadow-lg select-none',
        className
      )}
    >
      <div className="desktop pb-1 hidden container mx-auto justify-between md:flex">
        {filters.map(collection => {
          return (
            <button
              key={collection.collectionId}
              onClick={() => {
                if (onChangeFilter != null) {
                  onChangeFilter(collection.collectionId)
                }
              }}
              className={clsx(
                'focus:outline-none filter-btn',
                currentFilterId === collection.collectionId && 'active'
              )}
            >
              {collection.name}
            </button>
          )
        })}
      </div>
      <div className="mobile flex flex-col md:hidden">
        <button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className="w-full filter-btn-mobile flex justify-between items-center focus:outline-none"
        >
          <span>{currentFilterName}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            className={clsx(
              'ml-1 transform inline-block fill-current text-gray-500 w-6 h-6',
              isMenuOpen && 'rotate-180'
            )}
          >
            <path
              fillRule="evenodd"
              d="M15.3 10.3a1 1 0 011.4 1.4l-4 4a1 1 0 01-1.4 0l-4-4a1 1 0 011.4-1.4l3.3 3.29 3.3-3.3z"
            />
          </svg>
        </button>
        <div className={clsx('flex flex-col', !isMenuOpen && 'hidden')}>
          {filters.map(collection => {
            return (
              <button
                key={collection.collectionId}
                onClick={() => {
                  setIsMenuOpen(false)
                  if (onChangeFilter != null) {
                    onChangeFilter(collection.collectionId)
                  }
                }}
                className="focus:outline-none filter-btn-mobile"
              >
                {collection.name}
              </button>
            )
          })}
        </div>
      </div>
    </section>
  )
}
