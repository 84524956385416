const isButtonValid = function (buttonText, buttonLink) {
  return !(
    buttonLink == null ||
    buttonLink === '' ||
    buttonText == null ||
    buttonText === ''
  )
}

export default isButtonValid
