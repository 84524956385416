import React from 'react'
import CollectionProductCard from './CollectionProductCard'
import {
  getMetafieldFromProduct,
} from '../../utils/product-utils'

const CollectionProductGrid = ({ products = [] }) => {
  return (
    <section className="container mx-auto my-12 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-x-4 gap-y-10 md:gap-y-20">
      {products != null && products.length !== 0 ? (
        products.map(product => {
          const {
            id,
            handle,
            description,
            title,
            images: [firstImage],
            variants,
          } = product

          // TODO - product.availableForSale will indicate if any variants
          // is available for sale (in stock or can be bought regardless of
          // stock)
          // TODO - product.variant.availableForSale will indicate if it is
          // available for sale (same as above params)
          // TODO - product.variant.currentlyNotInStock -
          // Whether a product is out of stock but still available for
          // purchase (used for backorders). This is confusing.
          const size = getMetafieldFromProduct(product, 'info', 'size')
          const alcohol = getMetafieldFromProduct(product, 'info', 'alcohol')
          const pairings = getMetafieldFromProduct(product, 'info', 'pairings')
          const awards = getMetafieldFromProduct(product, 'info', 'awards')
          const afterTaxPricing = getMetafieldFromProduct(
            product,
            'pricing',
            'after_tax_price'
          )

          const availableVariants = variants.filter(singleVariant => {
            const visibilityMetafield = getMetafieldFromProduct(
              singleVariant,
              'visibility',
              'onlyPos'
            )

            return visibilityMetafield !== '1'
          })

          if (availableVariants.length === 0) {
            return null
          }

          return (
            <CollectionProductCard
              key={id}
              title={title}
              subtitle={size}
              description={description}
              alcohol={alcohol}
              awards={awards}
              pairings={pairings}
              smallerImage={firstImage?.smallerImage?.childImageSharp.fluid}
              smallImage={firstImage?.smallImage?.childImageSharp.fluid}
              bigImage={firstImage?.bigImage?.childImageSharp.fluid}
              product={{ ...product, variants: availableVariants }}
              productImgAlt={handle}
              isAfterTaxPrice={afterTaxPricing != null}
            />
          )
        })
      ) : (
        <p>No Products found!</p>
      )}
    </section>
  )
}

export default CollectionProductGrid
