import getFluidImg from '../../utils/getFluidImg'
import isButtonValid from '../../utils/isButtonValid'
import EndingSection from '../sections/EndingSection'
import Image from 'gatsby-image'
import ResolvingLink from '../ResolvingLink'
import React from 'react'

export default function EndingSectionSlice({ slice }) {
  if (slice == null) {
    return null
  }

  const {
    primary: {
      title: { text: titleText },
      description: { text: descriptionText },
      button_text: { text: buttonText },
      is_reversed: isReversed,
      button_link: buttonLink,
      image,
    },
  } = slice

  const { alt: imageAltText } = image
  const fluidImage = getFluidImg(image)

  const buttonIsValid = isButtonValid(buttonText, buttonLink.url)

  return (
    <EndingSection
      isReversed={isReversed}
      className="mt-12"
      imgBuilder={
        fluidImage != null
          ? className => {
              return (
                <Image
                  className={className}
                  fluid={fluidImage}
                  alt={imageAltText}
                />
              )
            }
          : null
      }
      titleBuilder={() => {
        return <h1 className="text-gray-title">{titleText}</h1>
      }}
      descriptionBuilder={() => {
        return <p className="text-gray-body">{descriptionText}</p>
      }}
      buttonBuilder={
        buttonIsValid
          ? () => {
              return (
                <ResolvingLink
                  link={buttonLink}
                  className="mt-8 btn btn-yellow-outline uppercase focus:outline-none"
                >
                  {buttonText}
                </ResolvingLink>
              )
            }
          : null
      }
    />
  )
}
